body {
  margin: 0;
  font-family: 'Avenir Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(#182330, #101010);
  // background-color: #f7fafc;
  background-attachment: fixed;
  overflow: overlay;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar- {
  width: 6px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  margin: 2px;
}
::-webkit-scrollbar-thumb {
  background: rgb(88, 88, 88);
  -webkit-box-shadow: none;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p:last-child {
  margin-bottom: 0px;
}

button:disabled {
  @apply cursor-not-allowed;
}

.required_label {
  &:after {
    content: ' *';
    @apply text-red-400;
  }
}

.select-special-container {
  @apply rounded-md;

  .tw-select__control {
    @apply bg-background-base border border-transparent focus:border-gray-300 focus:ring-0 focus:text-white;
  }
  .tw-select__multi-value,
  .tw-select__menu,
  .tw-select__menu-list,
  .tw-select__option {
    @apply bg-background-alt-2;
  }
  .tw-select__multi-value__label {
    @apply text-text-primary;
  }
  .tw-select__input-container{
    @apply text-white
  }
  .tw-select__single-value{
    @apply text-white
  }
}

.box {
  border-radius: 0.25rem;
  --b: 5px; /* thickness of the border */
  --w: 18px; /* width of border */
  --c: #fff; /* color of the border */

  border: var(--b) solid transparent; /* space for the border */
  background: linear-gradient(var(--c), var(--c)) top left,
    linear-gradient(var(--c), var(--c)) top left,
    linear-gradient(var(--c), var(--c)) bottom left,
    linear-gradient(var(--c), var(--c)) bottom left,
    linear-gradient(var(--c), var(--c)) top right,
    linear-gradient(var(--c), var(--c)) top right,
    linear-gradient(var(--c), var(--c)) bottom right,
    linear-gradient(var(--c), var(--c)) bottom right;
  background-size: var(--b) var(--w), var(--w) var(--b);
  background-origin: border-box;
  background-repeat: no-repeat;
}

.cube {
  position: relative;
  width: 6rem;
  height: 6rem;

  animation: rotate 3s ease infinite;
  animation-delay: 0.8s;
  transform-style: preserve-3d;
  transform: rotateX(-37.5deg) rotateY(45deg);

  & * {
    position: absolute;
    width: 6rem;
    height: 6rem;
  }

  .sides {
    & * {
      box-sizing: border-box;
      border: 0.12rem solid white;
      border-radius: 0.25rem;
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotateX(-37.5deg) rotateY(45deg);
  }
  50% {
    transform: rotateX(-37.5deg) rotateY(405deg);
  }
  100% {
    transform: rotateX(-37.5deg) rotateY(405deg);
  }
}

.cube .sides .top {
  animation: top-animation 3s ease infinite;
  animation-delay: 0ms;
  transform: rotateX(90deg) translateZ(96px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}

@keyframes top-animation {
  0% {
    opacity: 1;
    transform: rotateX(90deg) translateZ(100px);
  }
  20% {
    opacity: 1;
    transform: rotateX(90deg) translateZ(48px);
  }
  70% {
    opacity: 1;
    transform: rotateX(90deg) translateZ(48px);
  }
  90% {
    opacity: 1;
    transform: rotateX(90deg) translateZ(100px);
  }
  100% {
    opacity: 1;
    transform: rotateX(90deg) translateZ(100px);
  }
}
.cube .sides .bottom {
  animation: bottom-animation 3s ease infinite;
  animation-delay: 0ms;
  transform: rotateX(-90deg) translateZ(96px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}

@keyframes bottom-animation {
  0% {
    opacity: 1;
    transform: rotateX(-90deg) translateZ(100px);
  }
  20% {
    opacity: 1;
    transform: rotateX(-90deg) translateZ(48px);
  }
  70% {
    opacity: 1;
    transform: rotateX(-90deg) translateZ(48px);
  }
  90% {
    opacity: 1;
    transform: rotateX(-90deg) translateZ(100px);
  }
  100% {
    opacity: 1;
    transform: rotateX(-90deg) translateZ(100px);
  }
}
.cube .sides .front {
  animation: front-animation 3s ease infinite;
  animation-delay: 100ms;
  transform: rotateY(0deg) translateZ(96px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}

@keyframes front-animation {
  0% {
    opacity: 1;
    transform: rotateY(0deg) translateZ(96px);
  }
  20% {
    opacity: 1;
    transform: rotateY(0deg) translateZ(48px);
  }
  70% {
    opacity: 1;
    transform: rotateY(0deg) translateZ(48px);
  }
  90% {
    opacity: 1;
    transform: rotateY(0deg) translateZ(96px);
  }
  100% {
    opacity: 1;
    transform: rotateY(0deg) translateZ(96px);
  }
}
.cube .sides .back {
  animation: back-animation 3s ease infinite;
  animation-delay: 100ms;
  transform: rotateY(-180deg) translateZ(96px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}

@keyframes back-animation {
  0% {
    opacity: 1;
    transform: rotateY(-180deg) translateZ(96px);
  }
  20% {
    opacity: 1;
    transform: rotateY(-180deg) translateZ(48px);
  }
  70% {
    opacity: 1;
    transform: rotateY(-180deg) translateZ(48px);
  }
  90% {
    opacity: 1;
    transform: rotateY(-180deg) translateZ(96px);
  }
  100% {
    opacity: 1;
    transform: rotateY(-180deg) translateZ(96px);
  }
}
.cube .sides .left {
  animation: left-animation 3s ease infinite;
  animation-delay: 100ms;
  transform: rotateY(-90deg) translateZ(96px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}

@keyframes left-animation {
  0% {
    opacity: 1;
    transform: rotateY(-90deg) translateZ(96px);
  }
  20% {
    opacity: 1;
    transform: rotateY(-90deg) translateZ(48px);
  }
  70% {
    opacity: 1;
    transform: rotateY(-90deg) translateZ(48px);
  }
  90% {
    opacity: 1;
    transform: rotateY(-90deg) translateZ(96px);
  }
  100% {
    opacity: 1;
    transform: rotateY(-90deg) translateZ(96px);
  }
}
.cube .sides .right {
  animation: right-animation 3s ease infinite;
  animation-delay: 100ms;
  transform: rotateY(90deg) translateZ(96px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}

@keyframes right-animation {
  0% {
    opacity: 1;
    transform: rotateY(90deg) translateZ(96px);
  }
  20% {
    opacity: 1;
    transform: rotateY(90deg) translateZ(48px);
  }
  70% {
    opacity: 1;
    transform: rotateY(90deg) translateZ(48px);
  }
  90% {
    opacity: 1;
    transform: rotateY(90deg) translateZ(96px);
  }
  100% {
    opacity: 1;
    transform: rotateY(90deg) translateZ(96px);
  }
}

.react-confirm-alert-body-element {
  @apply overflow-hidden;
}

.paginate_section {
  .current {
    @apply bg-button-hover text-white;
  }

  ul {
    @apply flex flex-row flex-nowrap;

    li {
      @apply bg-button-base;

      a {
        @apply block px-3 py-2;
      }

      &:hover {
        @apply bg-button-hover text-white;
      }

      &:first-child {
        @apply rounded-l-lg;
      }
      &:last-child {
        @apply rounded-r-lg;
      }
    }
  }
}

/* Editor colors */
.demo-wrapper {
  .rdw-dropdown-selectedtext {
    @apply text-black;
  }

  li.rdw-dropdownoption-default {
    @apply text-black;
  }
}
