$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';

/* fontawesome. */
@import '@fortawesome/fontawesome-free/scss/brands';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
/* end fontawesome. */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Avenir Medium';
  src: local('Avenir Medium'),
    url(./assets/fonts/Avenir/AvenirLTStd-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Avenir';
  src: local('Avenir'),
    url(./assets/fonts/Avenir/AvenirLTStd-Book.ttf) format('truetype');
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

// .leaflet-control { z-index: 0 !important}
// .leaflet-pane { z-index: 0 !important}
// .leaflet-top, .leaflet-bottom {z-index: 0 !important}

.sliding-pane-overlay {
  z-index: 1000;
}